::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;
  min-height: 7rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.dark ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.dark ::-webkit-scrollbar-thumb {
  background: #666666;
}

/* Handle on hover */
.dark ::-webkit-scrollbar-thumb:hover {
  background: #515151;
}
