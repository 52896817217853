.apedao-spinner {
  border-radius: 50%;
  display: inline-block;
  border-top: 2px solid #AAA;
  border-right: 2px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.apedao-jumping-ape {
  animation: jump 0.9s ease-in infinite;
  transform-origin: bottom;
}

.apedao-jumping-ape g {
  fill: black !important;
}

@keyframes jump {
  0% {
    transform: translateY(0px) scaleY(0.85) scaleX(1.1);
  }
  20% {
    transform: scaleY(1) scaleX(1)
  }
  50% {
    transform: translateY(-15%) scaleY(1);
  }
  80% {
    transform: scaleY(1) scaleX(1)
  }
  100% {
    transform: translateY(0px) scaleY(0.85) scaleX(1.1);
  }
}
