.first_cart_item_animation {
  animation-name: first_cart_item_animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  
}
@keyframes first_cart_item_animation {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
