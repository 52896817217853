.apedao_image path {
    fill: rgb(240, 199, 58);
}

.apedao_image_dark path {
    fill: rgb(227, 227, 227);
}

.magicsea_image path {
    fill: #34aadc;
}

.magicsea_image_dark path {
    fill: rgb(227, 227, 227);
}
