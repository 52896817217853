.page-template-main-content::-webkit-scrollbar-track {
  margin-top: 3.5rem;
  margin-bottom: 2.5rem;
}

.page-template-main-content.with-sidebar::-webkit-scrollbar-track {
  margin-top: 7rem;
  margin-bottom: 2.5rem;
}

@screen sm {
  .page-template-main-content::-webkit-scrollbar-track {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .page-template-main-content.with-sidebar::-webkit-scrollbar-track {
    margin-top: 7rem;
    margin-bottom: 3.5rem;
  }
}

@screen md {
  .page-template-main-content::-webkit-scrollbar-track {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .page-template-main-content.with-sidebar::-webkit-scrollbar-track {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
}

body {
  --toastify-z-index: 10000;
}
