.footer-drop-shadow {
  box-shadow: 0 0 0.7rem #00000022;
}

.dark .footer-ape-image path {
  fill: rgb(227, 227, 227);
}

.footer-ape-image path {
  fill: #121212;
}

