@import-normalize;

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Exo';
  src: local('Exo'), url(./assets/fonts/Exo/Exo-VariableFont_wght.ttf);
}

@font-face {
  font-family: 'NotoSansMono';
  src: local('NotoSansMono'), url(./assets/fonts/NotoSansMono/NotoSansMono-VariableFont_wdth,wght.ttf);
}
