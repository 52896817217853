.flip-tile-inner {
    /*transition: transform 0.8s;*/
    transform-style: preserve-3d;
}

.flip-tile-flipped .flip-tile-inner {
    transform: rotateY(180deg);
}

.flip-tile-front, .flip-tile-back {
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

.flip-tile-back {
    transform: rotateY(180deg);
}
